h2 {
  font-size: 30px;
}

i {
  font-size: 20px;
}

.upload_input {
  border: 2px solid var(--primary);
  margin: 0px;
  padding: 7px;
}

.import_form {
  height: fit-content;
}

.rendered_emails_list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rendered_emails_list ul {
  list-style: none;
  max-height: 300px;
  overflow: scroll;
  padding: 5px;
  border: 1px solid gray;
  border-radius: 5px;
}

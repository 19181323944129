h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--primary);
  text-transform: uppercase;
}

h1,
h2 {
  margin: 20px 0;
}

h1 {
  font-size: 70px;
  font-weight: 400;
}

h2 {
  font-size: 60px;
  font-weight: 400;
}

h3 {
  font-size: 30px;
  font-weight: 400;
  line-height: 50px;
}

h5,
h6 {
  font-size: 60px;
  margin: 20px 0;
  font-weight: 400;
}

h6 {
  font-size: 30px;
  font-weight: 400;
}

a {
  text-decoration: underline !important;
}

strong {
  color: var(--primary);
}

p {
  font-size: 25px;
  text-align: justify;
  line-height: 40px;
}

span {
  color: blue;
  font-size: 20px;
}

.subtitle {
  text-align: center;
  align-self: center;
}

.card-group {
  display: flex;
  justify-content: center;
}

.mobile-phone {
  margin: auto;
  margin-top: 170px;
  padding: 10px 10px 30px;
  width: 350px;
  height: 600px;
  box-shadow: 0 0 20px #e2e2e2;
  border-radius: 30px;
}

.screen {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  border-radius: 30px;
  overflow-y: auto;
}

.illustration {
  border-radius: 6px;
  width: 85%;
  margin: 20px 0;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
}

.intro_text {
  font-size: 15px;
  line-height: 25px;
}

.secondary_strong {
  color: #f9ac02;
}

.stealth {
  width: 150px;
  padding: 40px;
  border-radius: 5%;
  background-color: var(--primary);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.4);
}

.more {
  height: 300px;
}

.contact-slide img {
  width: 200px;
  margin: 0;
}

.controls-arrow {
  color: var(--primary);
}

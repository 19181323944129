:root {
  --primary: #21b78e;
}

@font-face {
  font-family: "Bodoni Moda";
  src: url("assets/fonts/BodoniModaSC-VariableFont_opsz\,wght.ttf")
    format("truetype");
}

@font-face {
  font-family: "Exo2";
  src: url("assets/fonts/Exo2-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Montserrat";
  src: url("assets/fonts/Montserrat-VariableFont_wght.ttf") format("truetype");
}

body {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  font-family: "Montserrat";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.debug {
  color: green;
  position: fixed;
  top: 0;
  font-size: 15px;
  background-color: white;
  width: 100%;
  justify-content: center;

  z-index: 10;
  text-align: center;
}

.root {
  display: flex;

  align-items: center;
}

.later {
  text-align: center;
}

.app_header {
  position: absolute;
  width: 10%;
  bottom: 20px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.logo {
  width: 70px;
}

.logo_text {
  margin-top: 5px;
  font-family: "Exo2";
  font-size: 30px;
}

.blocked {
  display: none;
}

.submit_btn {
  height: 50px;
  width: 250px;
  color: white;
  font-family: "Exo2";
  font-size: 20px;
  background-color: #21b78e;
  border-radius: 5px;
  padding: 0px;
  border: none;
  cursor: pointer;
  transition: transform 0.3s;
}

.submit_btn:hover {
  background-color: rgb(0, 127, 99);
  transition: transform 0.3s;
}

@media screen and (max-width: 1200px) {
  .blocked {
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    background-color: white;
    z-index: 999;
    align-items: center;
    justify-content: center;
  }
  .blocked p {
    color: #21b78e;
    text-align: center;
    padding: 0 10px;
  }
}

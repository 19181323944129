/* .reveal img {
    max-height: none;
    max-width: none;
    margin: 0;

    margin-left: 20px;
    margin-right: 20px;
} */

.screen_container {
  width: 200px;
  border-radius: 6px;
  border: 1px solid rgb(201, 201, 201);
}

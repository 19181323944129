main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 500px; */
  height: 100vh;
  align-self: center;
}

.login_label {
  font-size: 50px;
}

.login_form {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 120px;
}

input {
  height: 40px;
  border: 1px solid gray;
  width: 250px;
  border-radius: 5px;
  padding: 0 10px 0 10px;
  margin: 0;
  box-sizing: border-box;
}

.modal {
  position: absolute;
  top: 50%;
  bottom: 50%;
  padding: 2rem;
  border: 0;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem 0.25rem hsl(0 0% 0% / 10%);
}
.modal p {
  text-align: center;
}

.modal-close-btn {
  font-size: 0.75em;
  font-family: "Exo2";
  position: absolute;
  top: 0.25em;
  right: 0.25em;
  font-size: 20px;
  color: gray;
  border: none;
  background: none;
  cursor: pointer;
}

.dialog-container {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100vh;
  width: 100vw;
  margin: 0;
  position: absolute;
  top: 0;
  z-index: 10;
}
